
import { defineComponent, ref, unref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';

import { Rules } from 'async-validator';

import { useCreatePromotion, useUpdatePromotion, usePromotion } from '@/composables/api';
import { PartialPromotion } from '@/interfaces/Promotion';
import useFormErrors from '@/utils/validate';
import { IntBoolean } from '@/services/api';

const DEFAULT_FORM_VALUES: PartialPromotion = {
  name: '',
  code: '',
  startedAt: '',
  endedAt: '',
  times: 1
};

const rules: Rules = {
  name: [
    {
      required: true
    }
  ],
  code: [
    {
      required: true
    }
  ],
  status: [
    {
      required: true
    }
  ]
};

const statusOptions = [
  {
    label: 'Enable',
    value: 'enable'
  },
  {
    label: 'Disable',
    value: 'disable'
  }
];

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const promotionId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { formErrors, bindFormItemError } = useFormErrors();
    const {
      isLoading: isCreatedLoading,
      mutate: create
    } = useCreatePromotion();
    const {
      isLoading: isUpdatedLoading,
      mutate: update
    } = useUpdatePromotion();

    const { data, dataUpdatedAt } = usePromotion({ promotionId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            update(
              { promotionId, data: formValues.value },
              {
                onSuccess() {
                  ElMessage.success({
                    message: 'success!',
                    type: 'success'
                  });

                  router.push({
                    name: 'list-promotions'
                  });
                },
                onError(error: any) {
                  ElMessage.error({
                    message: error.response?.data.message,
                    type: 'error'
                  });
                  formErrors.value = error.response?.data.errors;
                }
              }
            );

            return;
          }

          create(
            { data: formValues.value },
            {
              onSuccess() {
                ElMessage.success({
                  message: 'success!',
                  type: 'success'
                });

                formValues.value = DEFAULT_FORM_VALUES;
                router.push({
                  name: 'list-promotions'
                });
              },
              onError(error: any) {
                ElMessage.error({
                  message: error.response?.data.message,
                  type: 'error'
                });
                formErrors.value = error.response?.data.errors;
              }
            }
          );
        }
      });
    };

    return {
      IntBoolean,
      page,
      formValues,
      formRef,
      rules,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      statusOptions,
      formErrors,
      bindFormItemError
    };
  }
});
